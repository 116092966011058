































































































































































































































.form-box {
  .el-input {
    width: 300px;
  }
}
